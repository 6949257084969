import React from 'react'

import Layout from '../../../components/layout'
import { ReferenceForm } from '../../../components/job'

const ApplicationReferencePage = ({ params: { id } }) => {
  return (
    <Layout tocEnabled={true}>
      <ReferenceForm id={id} />
    </Layout>
  )
}

export default ApplicationReferencePage
